import { Autocomplete, Box, Button, Container, FormControlLabel, IconButton, InputAdornment, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useRef, useState } from "react";
import { Add, Close, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ProjectFieldingDetail from "./ProjectFieldingDetail";
import roles from "../../../shared/roles";
import { setParameterGetData } from "../project.reducer";
import { InterfaceAccountFielderData } from "../../account/account.interface";
import { formatName } from "../../../shared/utils";
import { getAccountFielder } from "../../account/account.api";
import ProjectList from "./ProjectList";
import ProjectMap from "./ProjectMap";

export default function Project() {

    const dispatch = useDispatch<any>()
    const theme = useTheme()
    const navigate = useNavigate()
    const mapContainerRef = useRef<any>()

    const { parameterGetData } = useSelector((state: RootState) => state.project)
    const { dataFielderList } = useSelector((state: RootState) => state.account)

    const [projectIdSelected, setProjectIdSelected] = useState<string | undefined>(undefined)
    const [search, setSearch] = useState("")
    const [viewMode, setViewMode] = useState<"LIST" | "MAP">("LIST")

    useEffect(() => {
        dispatch(getAccountFielder())
    }, [dispatch])

    return (
        <Box>
            <ProjectFieldingDetail projectId={projectIdSelected} onClose={() => setProjectIdSelected(undefined)} />
            <Box
                height={"85px"}
                bgcolor={theme.palette.background.default}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={1}
            >
                <Box>
                    {roles("PROJECT_CREATE") && (
                        <Button
                            sx={{
                                height: "85px",
                                borderRadius: 0
                            }}
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={() => navigate("/project/create")}
                        >
                            Add Project
                        </Button>
                    )}
                </Box>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                >
                    <TextField
                        label="Search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                            endAdornment:
                                parameterGetData.filter !== "" ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                dispatch(
                                                    setParameterGetData({
                                                        ...parameterGetData,
                                                        skip: 0,
                                                        filter: ""
                                                    })
                                                )
                                                setSearch("")
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    </InputAdornment>
                                ) : undefined
                        }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={(e) =>
                            e.key === "Enter" &&
                            dispatch(
                                setParameterGetData({
                                    ...parameterGetData,
                                    skip: 0,
                                    filter: search
                                })
                            )
                        }
                    />
                    {search !== parameterGetData.filter && (
                        <Button
                            onClick={() =>
                                dispatch(
                                    setParameterGetData({
                                        ...parameterGetData,
                                        skip: 0,
                                        filter: search
                                    })
                                )
                            }
                        >
                            Search
                        </Button>
                    )}
                </Box>
            </Box>
            <Container
                maxWidth={"xl"}
                sx={{
                    marginY: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 1
                }}
            >
                <FormControlLabel
                    label="View Mode: "
                    labelPlacement="start"
                    componentsProps={{ typography: { whiteSpace: "nowrap" } }}
                    control={
                        <Select
                            fullWidth
                            size="small"
                            value={viewMode}
                            onChange={(e) => setViewMode(e.target.value as unknown as "LIST" | "MAP")}
                            sx={{ marginLeft: 1 }}
                        >
                            <MenuItem value={"LIST"}>List View</MenuItem>
                            <MenuItem value={"MAP"}>Map View</MenuItem>
                        </Select>
                    }
                />
                <Box display={"flex"} flexGrow={1}></Box>
                <FormControlLabel
                    label="Fielder: "
                    labelPlacement="start"
                    control={
                        <Autocomplete
                            fullWidth
                            options={dataFielderList}
                            getOptionLabel={(value: InterfaceAccountFielderData) => formatName(value.fullName)}
                            sx={{
                                marginLeft: 1,
                                minWidth: 150
                            }}
                            value={
                                dataFielderList.filter((fielder) => parameterGetData.fielderIds.includes(fielder.id))[0]
                            }
                            onChange={(e, value) =>
                                dispatch(
                                    setParameterGetData({
                                        ...parameterGetData,
                                        skip: 0,
                                        fielderIds: Boolean(value) ? [value?.id] : []
                                    })
                                )
                            }
                            renderInput={(params) => <TextField {...params} />}
                        />
                    }
                />
                <FormControlLabel
                    label="Project Status: "
                    labelPlacement="start"
                    componentsProps={{ typography: { whiteSpace: "nowrap" } }}
                    control={
                        <Select
                            fullWidth
                            size="small"
                            sx={{ marginLeft: 1 }}
                            value={parameterGetData.projectStatus}
                            onChange={(e) =>
                                dispatch(
                                    setParameterGetData({
                                        ...parameterGetData,
                                        skip: 0,
                                        projectStatus: e.target.value
                                    })
                                )
                            }
                        >
                            <MenuItem value={0}>Active</MenuItem>
                            <MenuItem value={1}>Completed</MenuItem>
                            <MenuItem value={2}>Archived</MenuItem>
                        </Select>
                    }
                />
            </Container>
            {viewMode === "LIST" && (
                <ProjectList onSelect={setProjectIdSelected} />
            )}
            {viewMode === "MAP" && (
                <Box
                    ref={mapContainerRef}
                    display={"flex"}
                    flexGrow={1}
                    minHeight={window.innerHeight - mapContainerRef.current?.getBoundingClientRect().y}
                >
                    <ProjectMap
                        onClickGraphic={(projectId: string) => navigate(`/project/detail?id=${projectId}`)}
                    />
                </Box>
            )}
        </Box>
    )
}