import { Close } from "@mui/icons-material";
import { Card, CardContent, CardHeader, IconButton, Modal } from "@mui/material";

export default function CustomModal(props: { open: boolean, title: string, onClose: any, component: any, size?: "sm" | "md" | "lg", styles?: any }) {

    return (
        <Modal
            open={props.open}
        >
            <div className="custom-modal" >
                <Card className={`custom-modal-content ${props.size}`} style={props.styles ? props.styles : {}}>
                    <CardHeader
                        title={
                            <span>
                                {props.title}
                            </span>
                        }
                        action={
                            <IconButton color="inherit" onClick={() => props.onClose(false)}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        {props.component}
                    </CardContent>
                </Card>
            </div>
        </Modal>
    )
}