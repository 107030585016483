import { createSlice } from '@reduxjs/toolkit';
import {
  InterfaceFieldingAssignedActiveList,
  InterfaceFieldingDataList,
  InterfaceFieldingNotification,
  InterfaceFiledingTypeList,
} from './fielding.interface';
import {
  FieldingMarkerDefaultTimeOffsetGetFieldingId,
  FieldingMarkerDefaultTimeOffsetGetProjectId,
  FieldingNameGetByProjectId,
  fieldingAssignedGet,
  fieldingAttachmentDelete,
  fieldingComplete,
  fieldingDetailsGet,
  fieldingEdit,
  fieldingMarkerComplete,
  fieldingMarkerStart,
  fieldingReactive,
  fieldingTypeCreate,
  fieldingTypeDelete,
  fieldingTypeGet,
  fieldingAssignedActiveGet,
  fieldingMarkerAdd,
  fieldingNoteCreate,
  fieldingNoteCommentCreate,
  fieldingAssignFtr,
  fieldingGetNotification,
  fieldingAssignFtrAccept,
  fieldingAssignFtrDecline,
  fieldingClearNotification,
  fieldingSubmitProgress,
  fieldingMarkerAttachmentDelete,
  FieldingNameGetByFieldingId,
  requestDeleteAttachment,
  fieldingDelete,
  createDownloadLog,
  createTravelLog,
} from './fielding.api';

export interface FieldingState {
  loading: boolean;
  statusSelected: 0 | 1 | 2;
  dataTypeList: InterfaceFiledingTypeList;
  dataList: InterfaceFieldingDataList;
  dataMapList: InterfaceFieldingAssignedActiveList;
  dataNotificationList: Array<InterfaceFieldingNotification>;
}
const initialState: FieldingState = {
  loading: false,
  statusSelected: 0,
  dataTypeList: [],
  dataList: [],
  dataMapList: [],
  dataNotificationList: [],
};

export const fieldingSlice = createSlice({
  name: 'fielding',
  initialState,
  reducers: {
    setStatusSelected: (state, action) => {
      state.statusSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fieldingEdit.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingEdit.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingEdit.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingTypeCreate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingTypeCreate.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingTypeCreate.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingTypeDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingTypeDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingTypeDelete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingMarkerAttachmentDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingMarkerAttachmentDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingMarkerAttachmentDelete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(requestDeleteAttachment.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(requestDeleteAttachment.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(requestDeleteAttachment.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingTypeGet.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingTypeGet.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.dataTypeList = action.payload.data.data;
      }
    });
    builder.addCase(fieldingTypeGet.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingDetailsGet.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingDetailsGet.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingDetailsGet.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingAssignedGet.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingAssignedGet.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.dataList = action.payload.data.data;
      }
    });
    builder.addCase(fieldingAssignedGet.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingAssignedActiveGet.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingAssignedActiveGet.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.status) {
        state.dataMapList = action.payload.data.data;
      }
    });
    builder.addCase(fieldingAssignedActiveGet.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingComplete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingComplete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingComplete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingReactive.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingReactive.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingReactive.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingMarkerStart.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingMarkerStart.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingMarkerStart.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingMarkerComplete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingMarkerComplete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingMarkerComplete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingAttachmentDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingAttachmentDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingAttachmentDelete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(
      FieldingMarkerDefaultTimeOffsetGetFieldingId.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      FieldingMarkerDefaultTimeOffsetGetFieldingId.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      FieldingMarkerDefaultTimeOffsetGetFieldingId.rejected,
      (state, action) => {
        state.loading = false;
      }
    );

    builder.addCase(
      FieldingMarkerDefaultTimeOffsetGetProjectId.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      FieldingMarkerDefaultTimeOffsetGetProjectId.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      FieldingMarkerDefaultTimeOffsetGetProjectId.rejected,
      (state, action) => {
        state.loading = false;
      }
    );

    builder.addCase(FieldingNameGetByProjectId.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FieldingNameGetByProjectId.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(FieldingNameGetByProjectId.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(FieldingNameGetByFieldingId.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FieldingNameGetByFieldingId.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(FieldingNameGetByFieldingId.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingMarkerAdd.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingMarkerAdd.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingMarkerAdd.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingNoteCreate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingNoteCreate.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingNoteCreate.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingSubmitProgress.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingSubmitProgress.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingSubmitProgress.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingNoteCommentCreate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingNoteCommentCreate.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingNoteCommentCreate.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingAssignFtr.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingAssignFtr.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingAssignFtr.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingAssignFtrAccept.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingAssignFtrAccept.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingAssignFtrAccept.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingAssignFtrDecline.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingAssignFtrDecline.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingAssignFtrDecline.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingGetNotification.fulfilled, (state, action) => {
      if (action.payload.data.status) {
        state.dataNotificationList = action.payload.data.data;
      }
    });

    builder.addCase(fieldingClearNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingClearNotification.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingClearNotification.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fieldingDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingDelete.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createDownloadLog.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createDownloadLog.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createDownloadLog.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createTravelLog.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createTravelLog.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createTravelLog.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setStatusSelected } = fieldingSlice.actions;

export default fieldingSlice.reducer;
