import { Box, Button, Input, Slider, Typography } from "@mui/material"
import { InterfaceFieldingDetail } from "../fielding.interface"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { userInfo } from "../../../shared/utils"
import { fieldingSubmitProgress } from "../fielding.api"
import Swal from "sweetalert2"

interface InterfaceValues {
    customTypeId: string
    customTypeString: string | null,
    progressPercentage: number | undefined
    actualFielding: number
    requestFielding: number
}

export default function FieldingProgress(props: { details: InterfaceFieldingDetail; isComplete: boolean; onUpdateData: () => void }) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<InterfaceValues[]>([])
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        if (props.details) {
            initValues()
        }
    }, [props.details])

    const initValues = () => {
        const fieldingIdList = props.details.fieldingRequestMarkers.map((marker) => ({ customTypeId: marker.customTypeId, customTypeString: marker.customTypeString }))
        const fieldingIdListUnique = fieldingIdList.filter((value, index, self) => self.map(d => d.customTypeId).indexOf(value.customTypeId) === index)
        let mapValue: InterfaceValues[] = fieldingIdListUnique.map((data) => {
            const actual = props.details.fieldingRequestMarkers.filter((marker) => marker.customTypeId === data.customTypeId).reduce((a, b) => a + parseInt(b.actualFielding || "0"), 0)
            const request = props.details.fieldingRequestMarkers.filter((marker) => marker.customTypeId === data.customTypeId).reduce((a, b) => a + parseInt(b.requestedFielding || "0"), 0)
            const serverProgress = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.progressPercentage || 0
            return {
                customTypeId: data.customTypeId,
                customTypeString: data.customTypeString,
                progressPercentage: serverProgress || Math.round((actual / request) * 100),
                actualFielding: actual,
                requestFielding: request
            }
        })
        setValues(mapValue)

    }

    const handleSliderChange = async (e: any, customTypeId: any) => {
        let value = e.target.value
        value = value > 100 ? 100 : value < 0 ? 0 : value
        const minValue = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === customTypeId)?.progressPercentage || 0
        if (value >= minValue) {
            if (values.find((v) => v.customTypeId === customTypeId)) {
                setValues(
                    values.map((v) => ({
                        ...v,
                        progressPercentage: v.customTypeId === customTypeId ? value : v.progressPercentage
                    }))
                )
            }
        }
    }

    const handlerSubmit = async () => {
        if (props.isComplete && values.some((v) => v.progressPercentage && v.progressPercentage < 100)) {
            Swal.fire({
                icon: "error",
                title: "Failed!",
                text: "Fielding progress must be 100% in order to be completed"
            })
        }
        else {
            await dispatch(fieldingSubmitProgress(values.map(value => ({
                token: userInfo().token,
                customTypeId: value.customTypeId,
                progressPercentage: value.progressPercentage || 0,
                fieldingRequestId: props.details.id
            }))))
                .unwrap()
                .then((res: any) => {
                    if (res.status === 200) {
                        Swal.fire("Success!", "Progress updated!", "success").then(() => props.onUpdateData())
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Failed!",
                            text: "Failed update Progress"
                        })
                    }
                })
        }
    }

    const average = values.reduce((acc, curr) => acc + (curr.progressPercentage || 0), 0) / values.length

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
                paddingTop: 16
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    right: "22px",
                    top: 0,
                    display: "flex",
                    width: "150px",
                    justifyContent: "space-between"
                }}
            >
                <Typography fontWeight={"bold"} sx={{ fontSize: 18 }}>{`Average :`}</Typography>
                <Typography fontWeight={"bold"} sx={{ fontSize: 18 }}>{`${average.toFixed(2)}`}</Typography>
                <Typography fontWeight={"bold"} sx={{ fontSize: 18 }}>%</Typography>
            </Box>
            {values?.map((value) => (
                <Box sx={{ padding: "20px", width: "100%" }} key={value.customTypeId}>
                    <Typography
                        sx={{
                            fontSize: "16px"
                        }}
                    >
                        {value.customTypeString}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "auto", flexGrow: 1 }}>
                        <Slider
                            defaultValue={0}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            value={value.progressPercentage || 0}
                            onChange={(e) => isEdit && handleSliderChange(e, value.customTypeId)}
                            sx={{ flexGrow: 1 }}
                        />
                        <Input
                            value={value.progressPercentage}
                            onChange={(e) => handleSliderChange(e, value.customTypeId)}
                            disableUnderline
                            sx={{
                                marginLeft: "10px",
                                paddingLeft: "10px",
                                fontSize: "18px",
                                width: "50px"
                            }}
                            inputProps={{
                                min: 0,
                                max: 100
                            }}
                        />
                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "18px"
                            }}
                        >
                            %
                        </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography>Total Request: {value.requestFielding}</Typography>
                        <Typography>Total Actual: {value.actualFielding}</Typography>
                    </Box>
                </Box>
            ))}
            {isEdit
                ? (
                    <Button
                        variant="contained"
                        onClick={() => handlerSubmit()}
                        size="large"
                        sx={{ margin: "16px 0", width: "96%" }}
                        fullWidth
                    >
                        Submit Progress
                    </Button>
                )
                : (
                    <Box display={"flex"} flexGrow={1} gap={1} width={"100%"} marginY={2}>
                        <Button
                            variant="outlined"
                            size="large"
                            onClick={() => setIsEdit(true)}
                            fullWidth
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handlerSubmit()}
                            size="large"
                            fullWidth
                        >
                            Submit Progress
                        </Button>
                    </Box>
                )
            }
            <Typography color={"error"} variant="h6" align="center">
                WARNING: PROGRESS UPDATE & QUANTITY COMPLETION IS MANDATORY FOR MILAGE CLAIM TO BE ACKNOWLEDGED
            </Typography>
        </div>
    )
}
