import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function FieldingDetailEnd(props: { onCancel: () => void, onConfirm: (value: number) => void }) {

    const [miles, setMiles] = useState(0);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onConfirm(miles);
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <Typography variant="h6" align="center">You have traveled</Typography>
            <TextField
                variant="standard"
                name="miles"
                value={miles}
                onChange={(e) => setMiles(Number(e.target.value))}
                InputProps={{
                    endAdornment: "Miles"
                }}
                required
            />
            <Box display={"flex"} justifyContent={"space-between"} marginTop={2}>
                <Button variant="contained" color="warning" onClick={props.onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" type="submit">Confirm</Button>
            </Box>
        </Box>
    )
}