import { CalendarMonth } from "@mui/icons-material";
import { Button, List, ListItem, ListItemButton, ListItemText, Popover, TextField } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import ButtonOutlined from "../ButtonOutlined";

export default function CustomDatePicker(props: { setDateStart: any, dateStart: string, setDateEnd: any, dateEnd: string }) {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [optionSelected, setOptionSelected] = useState<{ name: string, value: number } | null>()
    const [customOption, setCustomOption] = useState<{ start: string | undefined, end: string | undefined } | null>()

    const options = [
        {
            name: "Last 7 Days",
            value: 7
        },
        {
            name: "Last 14 Days",
            value: 14
        },
        {
            name: "Last 30 Days",
            value: 30
        },
        {
            name: "Last 90 Days",
            value: 90
        },
    ]

    useEffect(() => {
        setOptionSelected(options[0])
    }, [])

    useEffect(() => {
        if (Boolean(optionSelected)) {
            let start = moment().subtract(optionSelected?.value, 'd').format('YYYY-MM-DD');
            let end = moment().format('YYYY-MM-DD');
            props.setDateStart(start)
            props.setDateEnd(end)
            setCustomOption(null)
        }
    }, [optionSelected])

    useEffect(() => {
        if (Boolean(customOption)) {
            if (Boolean(customOption?.start) && Boolean(customOption?.end)) {
                props.setDateStart(customOption?.start)
                props.setDateEnd(customOption?.end)
                setOptionSelected(null)
            }
        }
    }, [customOption])

    return (
        <div>
            <ButtonOutlined aria-describedby={"id"} onClick={(e) => setAnchorEl(e.currentTarget)} startIcon={<CalendarMonth />}>
                {Boolean(optionSelected)
                    ? `${optionSelected?.name}: ${props.dateStart} - ${props.dateEnd}`
                    : `Custom: ${props.dateStart} - ${props.dateEnd}`
                }
            </ButtonOutlined>
            <Popover
                id={"id"}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List>
                    {options.map(option => (
                        <ListItemButton key={option.value} onClick={() => setOptionSelected(option)}>
                            <ListItemText>{option.name}</ListItemText>
                        </ListItemButton>
                    ))}
                    <ListItem>
                        <ListItemText>
                            <TextField type="date" fullWidth={false} value={customOption?.start || ""} onChange={(e) => setCustomOption({ start: e.target.value, end: customOption?.end })} />{` `}
                            <TextField type="date" fullWidth={false} value={customOption?.end || ""} onChange={(e) => setCustomOption({ start: customOption?.start, end: e.target.value })} />
                        </ListItemText>
                    </ListItem>
                </List>
            </Popover>
        </div>
    )
}