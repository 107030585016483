import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, LinearProgress, Link, List, ListItem, TextField, Typography, useMediaQuery } from "@mui/material";
import profile from "../../../assets/images/profile.jpg"
import { AdsClick, CameraAlt, CheckCircle } from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import { Fragment, useEffect, useState } from "react";
import { formatName, isEmptyString, userInfo } from "../../../shared/utils";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, linkToUf, profileGet, profileUpdate, unLink } from "../myAccount.api";
import { RootState } from "../../../store";
import axios from "axios";
import { baseUrl } from "../../../state";
import CustomModal from "../../../shared/customModal/CustomModal";
import MyAccountArea from "./MyAccountArea";
import {
    initialPasswordData,
    InitialProfileData,
    InterfacePasswordData,
    InterfaceProfileData
} from "../myAccount.interface";
import notificationSuccess from "../../../shared/notificationSuccess";
import { InterfaceMarkerLine, InterfaceMarkerPoint } from "../../fielding/fielding.interface";
import MyAccountChooseAddress from "./MyAccountChooseAddress";
import roles from "../../../shared/roles";
import { fieldingTypeGet } from "../../fielding/fielding.api";
import notificationError from "../../../shared/notificationError";
import ButtonOutlined from "../../../shared/ButtonOutlined";
import Swal from "sweetalert2";
import useAddress from "../../../shared/useAddress";

export default function MyAccount() {

    const dispatch = useDispatch<any>()
    const isMobile = useMediaQuery('(max-width:768px)')
    const address = useAddress()

    const { dataProfile } = useSelector((state: RootState) => state.myAccount)
    const { dataTypeList } = useSelector((state: RootState) => state.fielding)

    const [values, setValues] = useState<InterfaceProfileData>(InitialProfileData)
    const [passwordValues, setPasswordValues] = useState<InterfacePasswordData>(initialPasswordData)
    const [isChangePassword, setIsChangePassword] = useState(false)
    const [isOnProgress, setIsOnProgress] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(75)
    const [modalMap, setModalMap] = useState(false)
    const [modalMapChoose, setModalMapChoose] = useState(false)
    const [center, setCenter] = useState<InterfaceMarkerPoint | null>(null)
    const [shape, setShape] = useState<InterfaceMarkerLine>([])
    const [linkUfShowEmail, setLinkUfShowEmail] = useState(false)
    const [linkUfEmailTemp, setLinkUfEmailTemp] = useState("")
    const [linkUfEmail, setLinkUfEmail] = useState("")

    useEffect(() => {
        dispatch(profileGet(userInfo().token))
        dispatch(fieldingTypeGet())
    }, [])

    useEffect(() => {
        setValues(dataProfile)
        setShape(dataProfile.workingAreaPolyData ? JSON.parse(dataProfile.workingAreaPolyData) : [])
    }, [dataProfile])

    useEffect(() => {
        if (values.address) {
            handleChangeCenter()
        }
    }, [values.address])

    const handleChangeCenter = () => {
        address.getCoordinate(values.address).then(response => setCenter({ latitude: response.latitude, longitude: response.longitude }))
    }

    const handleChangeValues = ((e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    })

    const handleChangePasswordValues = ((e: any) => {
        const { name, value } = e.target
        setPasswordValues({
            ...passwordValues,
            [name]: value
        })
    })

    const onChangeImage = async (e: any) => {
        if (e.target.files.length > 0) {
            setIsOnProgress(true)
            setUploadProgress(0)
            let data = new FormData();
            data.append("userId", userInfo().user.id)
            data.append("file", e.target.files[0])
            try {
                await axios.post(`${baseUrl}/api/Account/UploadProfilePicture`, data, {
                    onUploadProgress: (progressEvent) => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
                        setUploadProgress(percentCompleted)
                    }
                });
                setIsOnProgress(false)
                dispatch(profileGet(userInfo().token))
            } catch (error: any) {
                console.log(error);
            }
        }
    }

    const handleUpdate = () => {
        dispatch(profileUpdate({
            token: userInfo().token,
            id: values.id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            address: values.address,
            workingAreaPolyData: JSON.stringify(shape),
            phoneNumber: values.phoneNumber,
            profilePicturePath: values.profilePicturePath,
            skillTypeId: values.skillTypeId
        }))
            .unwrap()
            .then(() => {
                if (!isEmptyString(passwordValues.newPassword) && !isEmptyString(passwordValues.passwordConfirm)) {
                    dispatch(changePassword({
                        token: userInfo().token,
                        oldPassword: passwordValues.oldPassword,
                        newPassword: passwordValues.newPassword,
                        passwordConfirm: passwordValues.passwordConfirm
                    }))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("")
                                dispatch(profileGet(userInfo().token))
                            }
                            else {
                                notificationError(response)
                            }
                        })
                } else {
                    notificationSuccess("")
                    dispatch(profileGet(userInfo().token))
                }
            })
    }

    const handleChangeType = (checked: boolean, id: string) => {
        setValues({
            ...values,
            skillTypeId: checked ? (values.skillTypeId !== null ? `${values.skillTypeId}${id},` : `${id},`) : values.skillTypeId.replace(id, '').replace(',,', ',')
        })
    }

    const handleLinkUf = () => {
        if (linkUfEmail) {
            dispatch(linkToUf(linkUfEmail))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("")
                        dispatch(profileGet(userInfo().token))
                        setLinkUfEmail("")
                        setLinkUfEmailTemp("")
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
    }

    const handleUnlinkUf = () => {
        if (dataProfile.linkedEmail) {
            dispatch(unLink())
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("")
                        dispatch(profileGet(userInfo().token))
                        setLinkUfEmail("")
                        setLinkUfEmailTemp("")
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
    }

    const handleChangeAddress = (address: string) => {
        Swal.fire({
            title: 'Address Changed',
            text: 'Address changed to ' + address,
            icon: 'success',
            confirmButtonText: 'Done',
            showDenyButton: true,
            denyButtonText: 'Cancel',
        }).then(response => {
            if (response.isConfirmed) {
                setValues({ ...values, address })
                setModalMapChoose(false)
            } else {
                handleChangeCenter()
            }
        })
    }

    const handleChangeArea = (area: InterfaceMarkerLine) => {
        Swal.fire({
            title: 'Working Area Changed',
            text: 'Working area changed',
            icon: 'success',
            confirmButtonText: 'Done',
            showDenyButton: true,
            denyButtonText: 'Cancel',
        }).then(response => {
            if (response.isConfirmed) {
                setShape(area)
                setModalMap(false)
            }
        })
    }

    return (
        <Box minHeight={"93vh"} display={"flex"} alignItems={"center"} justifyContent={"center"} padding={1} overflow={"auto"}>
            <CustomModal
                open={modalMapChoose}
                onClose={() => setModalMapChoose(false)}
                title="Choose Address"
                component={
                    <div className="working-area">
                        <MyAccountChooseAddress
                            center={center}
                            setAddress={handleChangeAddress}
                        />
                    </div>
                }
            />
            <CustomModal
                open={modalMap}
                onClose={() => setModalMap(false)}
                title="Working Area"
                component={
                    <div className="working-area">
                        <MyAccountArea
                            center={center}
                            shape={shape}
                            setShape={handleChangeArea}
                        />
                    </div>
                }
            />
            <Box display={"flex"} flexDirection={isMobile ? "column" : "row"}>
                <Box padding={5} bgcolor={"#063058"} borderRadius={isMobile ? "50px 50px 0px 0px" : "50px 0px 0px 50px"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                    <img src={values.profilePicturePath ? baseUrl + "/api/Global/Download?path=" + values.profilePicturePath : profile} alt="profile" style={{ height: isMobile ? "200px" : "300px", width: isMobile ? "200px" : "300px", borderRadius: "50%", border: "5px solid white", objectFit: "cover" }} />
                    <label htmlFor="button-file">
                        <input
                            style={{ display: "none" }}
                            id="addKml"
                            type="file"
                            accept="image/*"
                            onChange={onChangeImage}
                        />
                        <label htmlFor="addKml">
                            <Button startIcon={<CameraAlt />} color="warning" sx={{ marginTop: 2 }} component={"span"}>Change Profile Picture</Button>
                            {isOnProgress && <LinearProgress variant="determinate" value={uploadProgress} />}
                        </label>
                    </label>
                    <Typography color={"white"} align="center" variant="h4" marginTop={2}>
                        {formatName(values.firstName)} {formatName(values.lastName)}
                    </Typography>
                    <Typography color={"white"} align="center" variant="body1" marginTop={2}>
                        Joined Since {values.joinDateString}
                    </Typography>
                    <Box marginTop={2} gap={1} display={"flex"} flexDirection={"column"}>
                        <Box display={"flex"} gap={1}>
                            <LinkIcon style={{ color: "white" }} />
                            <Link style={{ color: "white", cursor: "pointer" }} component={"button"} onClick={() => setLinkUfShowEmail(last => !last)}>
                                Link account to Utility Fielding
                            </Link>
                            {dataProfile.linkedEmail && (<CheckCircle sx={{ color: "greenyellow" }} />)}
                        </Box>
                        {linkUfShowEmail && (
                            <Fragment>
                                <TextField
                                    fullWidth
                                    placeholder="Email"
                                    value={dataProfile.linkedEmail || linkUfEmail || linkUfEmailTemp}
                                    onChange={(e) => setLinkUfEmail(e.target.value)}
                                    InputProps={{
                                        style: { backgroundColor: "white" },
                                    }}
                                />
                                {dataProfile.linkedEmail
                                    ? <Button sx={{ marginTop: 1 }} variant="contained" onClick={handleUnlinkUf}>Unlink</Button>
                                    : <Button sx={{ marginTop: 1 }} variant="contained" onClick={handleLinkUf}>Link</Button>
                                }
                            </Fragment>
                        )}
                    </Box>
                </Box>
                <Box maxWidth={isMobile ? undefined : "40vw"} maxHeight={isMobile ? undefined : "75vh"} padding={5} bgcolor={"white"} borderRadius={isMobile ? "0px 0px 50px 50px" : "0px 50px 50px 0px"} overflow={"auto"}>
                    <Grid container spacing={3} alignItems={"flex-start"}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h5">
                                Manage Profile
                            </Typography>
                            <Typography variant="h6">
                                {formatName(values.firstName)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="First Name" name="firstName" value={formatName(values.firstName) || ""} onChange={handleChangeValues} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Last Name" name="lastName" value={formatName(values.lastName) || ""} onChange={handleChangeValues} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Email" name="email" value={values.email || ""} onChange={handleChangeValues} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Phone" name="phoneNumber" value={values.phoneNumber} onChange={handleChangeValues} type="tel" />
                        </Grid>
                        <Grid item xs={9} md={5}>
                            <TextField fullWidth label={values.roles.includes("FielderSimple") ? "Start Location" : "Address"} name="address" value={values.address || ""} onChange={handleChangeValues} />
                        </Grid>
                        <Grid item xs={3} md={1}>
                            <IconButton onClick={() => setModalMapChoose(true)}>
                                <AdsClick />
                            </IconButton>
                        </Grid>
                        {roles("WORKING_AREA") && (
                            <Fragment>
                                <Grid item xs={12} md={6}>
                                    <ButtonOutlined fullWidth onClick={() => setModalMap(true)}>
                                        Working Area
                                    </ButtonOutlined>
                                    <Typography variant="subtitle2">
                                        <br />
                                        Last modified at {values.lastWorkingAreaModifiedDate} by {values.lastWorkingAreaModifiedBy}
                                    </Typography>
                                </Grid>
                            </Fragment>
                        )}
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={1} alignItems={"flex-start"}>
                                {values.roles.includes("FielderSimple") && (
                                    <Grid item xs={12} md={6} sx={isMobile ? undefined : { borderRight: "1px solid lightgrey" }}>
                                        <Fragment>
                                            <Typography variant="h6">
                                                Skill Type
                                            </Typography>
                                            <List>
                                                {dataTypeList.map(type => (
                                                    <ListItem key={type.id}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox color="primary" checked={(values.skillTypeId || "").includes(type.id)} onChange={(e) => handleChangeType(e.target.checked, type.id)} />
                                                            }
                                                            label={type.name}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Fragment>
                                    </Grid>
                                )}
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6">
                                        User Role
                                    </Typography>
                                    <List>
                                        {values.roles.includes("FPCompanyAdmin") && (
                                            <ListItem>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox color="primary" checked={true} />
                                                    }
                                                    label="Company Admin"
                                                />
                                            </ListItem>
                                        )}
                                        {values.roles.includes("FieldingManager") && (
                                            <ListItem>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox color="primary" checked={true} />
                                                    }
                                                    label="Assign Fielding"
                                                />
                                            </ListItem>
                                        )}
                                        {values.roles.includes("FieldingRequestSimple") && (
                                            <ListItem>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox color="primary" checked={true} />
                                                    }
                                                    label="Requestor"
                                                />
                                            </ListItem>
                                        )}
                                        {values.roles.includes("FielderSimple") && (
                                            <ListItem>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox color="primary" checked={true} />
                                                    }
                                                    label="Fielder"
                                                />
                                            </ListItem>
                                        )}
                                        {values.roles.includes("PaymentProcessor") && (
                                            <ListItem>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox color="primary" checked={true} />
                                                    }
                                                    label="Administrator"
                                                />
                                            </ListItem>
                                        )}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        {isChangePassword
                            ? (
                                <Fragment>
                                    <Grid item xs={12} md={6}>
                                        <TextField fullWidth type="password" label="Old Password" name="oldPassword" value={passwordValues.oldPassword} onChange={handleChangePasswordValues} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box display={"flex"} justifyContent={"flex-end"}>
                                            <ButtonOutlined onClick={() => setIsChangePassword(false)}>Cancel Change Password</ButtonOutlined>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField fullWidth type="password" label="New Password" name="newPassword" value={passwordValues.newPassword} onChange={handleChangePasswordValues} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField fullWidth type="password" label="Confirm New Password" name="passwordConfirm" value={passwordValues.passwordConfirm} onChange={handleChangePasswordValues} />
                                    </Grid>
                                </Fragment>
                            )
                            : (
                                <Fragment>
                                    <Grid item xs={12} md={12}>
                                        <ButtonOutlined fullWidth onClick={() => setIsChangePassword(true)}>Change Password</ButtonOutlined>
                                    </Grid>
                                </Fragment>
                            )
                        }
                        <Grid item xs={12} md={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={
                                    (dataProfile.firstName === values.firstName &&
                                        dataProfile.lastName === values.lastName &&
                                        dataProfile.email === values.email &&
                                        dataProfile.workingAreaPolyData === JSON.stringify(shape) &&
                                        dataProfile.address === values.address &&
                                        dataProfile.phoneNumber === values.phoneNumber &&
                                        dataProfile.skillTypeId === values.skillTypeId) && (isEmptyString(passwordValues.newPassword) || isEmptyString(passwordValues.passwordConfirm))
                                }
                                onClick={handleUpdate}
                            >
                                Save Changes
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}