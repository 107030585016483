import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import { InterfaceFieldingDetail } from "../fielding.interface"
import { fieldingEdit } from "../fielding.api"
import { userInfo } from "../../../shared/utils"
import moment from "moment"
import notificationSuccess from "../../../shared/notificationSuccess"
import { useEffect, useState } from "react"

export default function FieldingDetailEdit(props: {
    details: InterfaceFieldingDetail
    markerId: string
    setDetails: (params: InterfaceFieldingDetail) => void
    onClose: () => void
}) {
    const dispatch = useDispatch<any>()

    const [timer, setTimer] = useState("")

    useEffect(() => {
        let firstUnix = moment().unix() / 60
        let i = setInterval(() => {
            let nowUnix = moment().unix() / 60
            let duration = moment.duration(nowUnix - firstUnix, "minutes")
            setTimer(
                String(duration.hours()).padStart(2, "0") +
                ":" +
                String(duration.minutes()).padStart(2, "0") +
                ":" +
                String(duration.seconds()).padStart(2, "0")
            )
        }, 1000)
        return () => clearInterval(i)
    }, [])

    const handleChangeMarker = (id: string, name: string, value: any) => {
        props.setDetails({
            ...props.details,
            fieldingRequestMarkers: props.details.fieldingRequestMarkers.map((marker) => {
                if (marker.id === id) {
                    return {
                        ...marker,
                        [name]: value
                    }
                }
                return marker
            })
        })
    }

    const convertDateToDDMMYYYY = (dateString: string) => {
        const [day, month, year] = dateString.split('/');
        return `${month}/${day}/${year}`;
    };

    const handleSave = () => {
        dispatch(
            fieldingEdit({
                token: userInfo().token,
                fieldingRequestId: props.details.id,
                dueDateString: convertDateToDDMMYYYY(props.details.dueDateString),
                colorCode: props.details.colorCode,
                customTypeId: props.details.customTypeId,
                fielderIds: props.details.fielderIds,
                requestedStartDateString: props.details.requestedStartDateString === "-" ? null : props.details.requestedStartDateString,
                requestedDueDateString: props.details.requestedDueDateString === "-" ? null : props.details.requestedDueDateString,
                editedFieldingRequestMarkers: props.details.fieldingRequestMarkers.map((marker) => {
                    return {
                        ...marker,
                        timeOffset: moment().utcOffset() * -1,
                        fieldingRequestMarkerId: marker.id || "",
                        lineData: JSON.stringify(marker.lineData),
                        shapeData: JSON.stringify(marker.shapeData)
                    }
                })
            })
        )
            .unwrap()
            .then(async (response: any) => {
                if (response.status === 200) {
                    notificationSuccess("Field request successfully edited").then(() => {
                        props.onClose()
                    })
                }
            })
    }

    return (
        <Box>
            <Grid sx={{ padding: "12px 0" }}>
                <Typography variant="body1" align="right">
                    {timer}
                </Typography>
                <TextField
                    margin="dense"
                    value={
                        props.details.fieldingRequestMarkers.length > 0
                            ? props.details.fieldingRequestMarkers.find(marker => marker.id === props.markerId)?.subject
                            : ""
                    }
                    onChange={(e) => handleChangeMarker(props.markerId, "subject", e.target.value)}
                    label="Subject"
                />
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <Typography sx={{ paddingLeft: "14px" }}>
                        Type :{" "}
                        {props.details.fieldingRequestMarkers.length > 0
                            ? props.details.fieldingRequestMarkers.find(marker => marker.id === props.markerId)?.customTypeString
                            : ""}
                    </Typography>
                </Grid>
                <Grid item sm={6}>
                    <Typography sx={{ paddingLeft: "14px" }}>
                        {props.details.fieldingRequestMarkers.length > 0
                            ? props.details.fieldingRequestMarkers.find(marker => marker.id === props.markerId)?.requestedAmountTypeString
                            : ""}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ padding: "12px 0" }}>
                <Grid item sx={{ width: "100%" }} sm={6}>
                    <TextField
                        margin="dense"
                        value={
                            props.details.fieldingRequestMarkers.length > 0
                                ? props.details.fieldingRequestMarkers.find(marker => marker.id === props.markerId)?.requestedFielding
                                : ""
                        }
                        label="Request"
                        InputProps={{
                            style: { color: "black", WebkitTextFillColor: "black" }
                        }}
                        disabled
                    />
                </Grid>
                <Grid item sx={{ width: "100%" }} sm={6}>
                    <TextField
                        margin="dense"
                        value={
                            props.details.fieldingRequestMarkers.length > 0
                                ? props.details.fieldingRequestMarkers.find(marker => marker.id === props.markerId)?.actualFielding
                                : ""
                        }
                        onChange={(e) => handleChangeMarker(props.markerId, "actualFielding", e.target.value)}
                        label="Actual Fielding"
                    />
                </Grid>
            </Grid>
            <TextField
                margin="dense"
                value={
                    props.details.fieldingRequestMarkers.length > 0 ? props.details.fieldingRequestMarkers.find(marker => marker.id === props.markerId)?.notes : ""
                }
                onChange={(e) => handleChangeMarker(props.markerId, "notes", e.target.value)}
                label="Notes"
                multiline
            />
            <br />
            <br />
            <Button fullWidth variant="contained" onClick={handleSave}>
                Submit
            </Button>
        </Box>
    )
}
