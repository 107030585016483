import { AssignmentTurnedIn, Construction, Edit, ExpandLess, ExpandMore, FolderOpen, Image, NearMe, PlayArrowOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Chip, Collapse, Container, Divider, Grid, IconButton, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { fieldingAssignedGet, fieldingComplete } from "../fielding.api";
import { InterfaceFieldingDataDetail } from "../fielding.interface";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../shared/customModal/CustomModal";
import FieldingNotes from "./FieldingNotes";
import FieldingUploadImages from "./FieldingUploadImages";
import { setStatusSelected } from "../fielding.reducer";
import progressColor from "../../../shared/progressColor";
import hot from "../../../assets/images/hot.png"

export default function FieldingList() {

    const theme = useTheme()
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    const isMobile = useMediaQuery("(max-width:768px)")

    const { statusSelected, dataList } = useSelector((state: RootState) => state.fielding)

    const [idExpandedList, setIdExpandedList] = useState<string[]>([])
    const [dataSelected, setDataSelected] = useState<InterfaceFieldingDataDetail | null>(null)
    const [modalNotes, setModalNotes] = useState(false)
    const [modalImages, setModalImages] = useState(false)

    useEffect(() => {
        dispatch(fieldingAssignedGet(statusSelected))
    }, [dispatch, statusSelected])

    useEffect(() => {
        if (dataSelected !== null) {
            const projectSelected = dataList.find(data => data.details.map(detail => detail.fieldingRequestId).includes(dataSelected.fieldingRequestId))
            if (projectSelected) {
                const detailSelected = projectSelected.details.find(detail => detail.fieldingRequestId === dataSelected.fieldingRequestId)
                if (detailSelected) {
                    if (JSON.stringify(detailSelected) !== JSON.stringify(dataSelected)) {
                        setDataSelected(detailSelected)
                    }
                }
            }
        }
    }, [dataList, dataSelected])

    const toggleExpand = (id: string) => {
        if (idExpandedList.includes(id)) {
            setIdExpandedList(idExpandedList.filter(i => i !== id))
        }
        else {
            setIdExpandedList([...idExpandedList, id])
        }
    }

    const handleComplete = (id: string) => {
        dispatch(fieldingComplete(id))
            .unwrap()
            .then(() => {
                dispatch(fieldingAssignedGet(statusSelected))
            })
    }

    const statusList = [
        {
            name: "Active Jobs",
            value: 0,
            icon: <Construction />
        },
        {
            name: "Completed",
            value: 1,
            icon: <AssignmentTurnedIn />
        },
        {
            name: "Archived",
            value: 2,
            icon: <FolderOpen />
        }
    ]

    return (
        <Container maxWidth="xl" >
            <CustomModal
                open={Boolean(dataSelected) && modalNotes}
                onClose={() => { setDataSelected(null); setModalNotes(false) }}
                title="Notes"
                size="sm"
                component={dataSelected !== null && <FieldingNotes fieldingRequestId={dataSelected?.fieldingRequestId} notes={dataSelected?.fieldingRequestNotes} onUpdateData={() => dispatch(fieldingAssignedGet(statusSelected))} />}
            />
            <CustomModal
                open={Boolean(dataSelected) && modalImages}
                onClose={() => { setDataSelected(null); setModalImages(false) }}
                title="Upload File"
                component={dataSelected !== null && <FieldingUploadImages fieldingId={dataSelected?.fieldingRequestId} onClose={() => { setDataSelected(null); setModalImages(false) }} />}
            />
            <Box
                bgcolor={theme.palette.background.default}
                borderRadius={1}
                marginBottom={2}
            >
                <Box>
                    <Tabs variant={isMobile ? "fullWidth" : "standard"} value={statusSelected} onChange={(e, value) => dispatch(setStatusSelected(value))}>
                        {statusList.map((status, statusIndex) => (
                            <Tab
                                key={statusIndex}
                                label={status.name}
                                value={status.value}
                                icon={status.icon}
                                iconPosition="start"
                                sx={{ paddingY: 0 }}
                            />
                        ))}
                    </Tabs>
                </Box>
                <Divider />
                <Box
                    padding={2}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                >
                    {dataList.map((fielding, fieldingIndex) => (
                        <Card key={fieldingIndex} variant="outlined">
                            <CardContent>
                                <Box display="flex" alignItems={"center"} justifyContent="space-between" sx={{ cursor: "pointer" }} onClick={() => toggleExpand(fielding.projectId)}>
                                    <Box display={"flex"} alignItems={"center"} gap={1}>
                                        <Typography variant="h6" color={fielding.isHotJob ? "red" : undefined}>
                                            {fielding.title}
                                        </Typography>
                                        {fielding.isHotJob && (
                                            <img src={hot} alt="hot" style={{ width: "20px" }} />
                                        )}
                                        <Chip label={`${fielding.fieldingRequestCount} Request`} />
                                    </Box>
                                    <IconButton>
                                        {idExpandedList.includes(fielding.projectId)
                                            ? <ExpandLess />
                                            : <ExpandMore />
                                        }
                                    </IconButton>
                                </Box>
                                <Collapse in={idExpandedList.includes(fielding.projectId)}>
                                    <Box display={"flex"} flexDirection={"column"} gap={1} marginTop={2}>
                                        {fielding.details.map((detail, detailIndex) => (
                                            <Box key={detailIndex} bgcolor={"#f8f8f8"} padding={2} borderRadius={1}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} md={2}>
                                                        <Typography fontWeight={"bold"}>Date</Typography>
                                                        <Typography fontWeight={"light"}>{detail.dueDate}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} md={2}>
                                                        <Typography fontWeight={"bold"}>Request Number</Typography>
                                                        <Typography fontWeight={"light"}>{detail.fieldingRequest}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6} md={12}>
                                                                <Typography color={progressColor(detail.averageProgressPercentage)}>Progress: {detail.averageProgressPercentage.toFixed(2)}%</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} md={12}>
                                                                <Typography>Total Miles: {detail.milesTotal} Miles</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Grid container spacing={1}>
                                                            {statusSelected !== 0 && (
                                                                <Grid item xs={12} lg={3}>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={6} sm={4} md={6} lg={3}>
                                                                <Button fullWidth variant="contained" color="inherit" startIcon={<Image />} onClick={() => { setDataSelected(detail); setModalImages(true) }}>Upload</Button>
                                                            </Grid>
                                                            <Grid item xs={6} sm={4} md={6} lg={3}>
                                                                <a href={`https://www.google.com/maps/search/?api=1&query=${detail.firstMarkerLocation.split(',')[1]}%2C${detail.firstMarkerLocation.split(',')[0]}`} target="_blank">
                                                                    <Button fullWidth variant="contained" startIcon={<PlayArrowOutlined />} sx={{ bgcolor: '#4ADEDE' }}>Direction</Button>
                                                                </a>
                                                            </Grid>
                                                            <Grid item xs={6} sm={4} md={6} lg={3}>
                                                                <Button fullWidth variant="contained" startIcon={<Edit />} sx={{ bgcolor: '#1560BD' }} onClick={() => { setDataSelected(detail); setModalNotes(true) }}>Notes</Button>
                                                            </Grid>
                                                            {statusSelected === 0 && (
                                                                <Grid item xs={6} sm={4} md={6} lg={3}>
                                                                    <Button fullWidth variant="contained" color="success" startIcon={<NearMe />} onClick={() => navigate(`/fielding/detail?id=${detail.fieldingRequestId}`)}>Start</Button>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ))}
                                    </Box>
                                </Collapse>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Box>
        </Container>
    )
}