import { Box, CircularProgress } from "@mui/material";
import { useState } from "react";

export default function ExpenseDetailAttachmentItem(props: { name: string, path: string, onClick?: () => void }) {

    const [isLoading, setIsLoading] = useState(true)

    return (
        <Box
            position={"relative"}
            width={150}
            height={150}
        >
            {isLoading && (
                <Box
                    position={"absolute"}
                    top={"50%"}
                    left={"50%"}
                    sx={{ transform: "translate(-50%, -50%)" }}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}
            <img
                src={props.path + "?width=150"}
                height={150}
                alt={props.name}
                onLoad={() => setIsLoading(false)}
                onError={(err) => setIsLoading(false)}
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                }}
                onClick={props.onClick}
            />
        </Box>
    )
}