import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CustomModal from '../../../shared/customModal/CustomModal';
import {
  Add,
  Close,
  Delete,
  Edit,
  Key,
  Search,
  Star,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import AccountForm from './AccountForm';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAccount, getAccount } from '../account.api';
import { RootState } from '../../../store';
import Swal from 'sweetalert2';
import notificationSuccess from '../../../shared/notificationSuccess';
import notificationError from '../../../shared/notificationError';
import { InitialAccountData, InterfaceAccountData } from '../account.interface';
import { setParameterGetData } from '../account.reducer';
import AccountResetPassword from './AccountResetPassword';
import AccountRating from './AccountRating';
import roles from '../../../shared/roles';
import Pagination from '../../../shared/Pagination';
import TableHeader from '../../../shared/TableHeader';
import { formatName } from '../../../shared/utils';

export default function Account() {
  const dispatch = useDispatch<any>();

  const { parameterGetData, dataList, dataTotal } = useSelector(
    (state: RootState) => state.account
  );

  const [modalCreate, setModalCreate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalResetPassword, setModalResetPassword] = useState(false);
  const [modalRating, setModalRating] = useState(false);
  const [dataSelected, setDataSelected] =
    useState<InterfaceAccountData>(InitialAccountData);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getAccount(parameterGetData));
  }, [parameterGetData]);

  const handleDelete = (id: string) => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure want to delete this data?',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAccount(id))
          .unwrap()
          .then((response: any) => {
            if (response.status === 200) {
              notificationSuccess('Success');
              dispatch(getAccount(parameterGetData));
            } else {
              notificationError(response);
            }
          });
      }
    });
  };

  const handleShowRating = (data: InterfaceAccountData) => {
    if (data.averageRatingString === '0,0') return true;
    setDataSelected(data);
    setModalRating(true);
  };

  return (
    <div className='project'>
      <CustomModal
        open={modalCreate}
        onClose={() => setModalCreate(false)}
        title='Create User'
        component={
          <AccountForm onClose={() => setModalCreate(false)} type='create' />
        }
      />
      <CustomModal
        open={modalEdit}
        onClose={() => setModalEdit(false)}
        title='Edit User'
        component={
          <AccountForm
            onClose={() => setModalEdit(false)}
            type='edit'
            data={dataSelected}
          />
        }
      />
      <CustomModal
        open={modalResetPassword}
        onClose={() => setModalResetPassword(false)}
        title='Reset Password'
        size='sm'
        component={
          <AccountResetPassword
            onClose={() => setModalResetPassword(false)}
            data={dataSelected}
          />
        }
      />
      <CustomModal
        open={modalRating}
        onClose={() => setModalRating(false)}
        title='Rating Detail'
        size='sm'
        component={
          <AccountRating
            onClose={() => setModalRating(false)}
            data={dataSelected}
          />
        }
      />
      <div className='menu-header scrolling'>
        {roles('USER_CREATE') && (
          <Button
            className='add-button'
            variant='contained'
            color='primary'
            startIcon={<Add />}
            onClick={() => setModalCreate(true)}
          >
            Add User
          </Button>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TextField
            label='Search'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
              endAdornment:
                parameterGetData.filter !== '' ? (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => {
                        dispatch(
                          setParameterGetData({
                            ...parameterGetData,
                            skip: 0,
                            filter: '',
                          })
                        );
                        setSearch('');
                      }}
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) =>
              e.key === 'Enter' &&
              dispatch(
                setParameterGetData({
                  ...parameterGetData,
                  skip: 0,
                  filter: search,
                })
              )
            }
          />
          {search !== parameterGetData.filter && (
            <Button
              onClick={() =>
                dispatch(
                  setParameterGetData({
                    ...parameterGetData,
                    skip: 0,
                    filter: search,
                  })
                )
              }
            >
              Search user
            </Button>
          )}
        </div>
      </div>
      <div className="container">
        <table className='custom-table'>
          <thead>
            <tr>
              <th>
                <TableHeader
                  name='Name'
                  field='firstName'
                  fieldSelected={parameterGetData.sortField}
                  sortTypeSelected={parameterGetData.sortType}
                  onAscending={() =>
                    dispatch(
                      setParameterGetData({
                        ...parameterGetData,
                        sortField: 'firstName',
                        sortType: 0,
                      })
                    )
                  }
                  onDescending={() =>
                    dispatch(
                      setParameterGetData({
                        ...parameterGetData,
                        sortField: 'firstName',
                        sortType: 1,
                      })
                    )
                  }
                />
              </th>
              <th>
                <TableHeader
                  name='Email'
                  field='email'
                  fieldSelected={parameterGetData.sortField}
                  sortTypeSelected={parameterGetData.sortType}
                  onAscending={() =>
                    dispatch(
                      setParameterGetData({
                        ...parameterGetData,
                        sortField: 'email',
                        sortType: 0,
                      })
                    )
                  }
                  onDescending={() =>
                    dispatch(
                      setParameterGetData({
                        ...parameterGetData,
                        sortField: 'email',
                        sortType: 1,
                      })
                    )
                  }
                />
              </th>
              <th>
                Role
              </th>
              <th>
                Rating
              </th>
              {roles('USER_EDIT') && <th>Action</th>}
            </tr>
            <tr>
              <th className='space' colSpan={6}></th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((data) => (
              <tr key={data.id}>
                <td>
                  {formatName(data.firstName)} {formatName(data.lastName)}
                </td>
                <td>{data.email}</td>
                <td>{data.rolesDisplay}</td>
                <td>
                  {(data.rolesDisplay.split(', ').includes('Fielder') ||
                    data.rolesDisplay.split(',').includes('Fielder')) && (
                      <Box display={'flex'}>
                        <IconButton
                          onClick={() => {
                            handleShowRating(data);
                          }}
                          sx={{
                            color:
                              data.averageRatingString === '0.0'
                                ? 'lightgray'
                                : 'gold',
                            marginBottom: '3px',
                          }}
                        >
                          <Star />
                        </IconButton>
                        <Typography
                          onClick={() => {
                            handleShowRating(data);
                          }}
                          display={'flex'}
                          sx={{ alignItems: 'center' }}
                        >
                          {data.averageRatingString}
                        </Typography>
                        <Button
                          color='info'
                          sx={{ fontSize: '14px' }}
                          disabled={data.averageRatingString === '0.0'}
                          onClick={() => {
                            handleShowRating(data);
                          }}
                        >
                          {data.ratingTotal} Review
                        </Button>
                      </Box>
                    )}
                </td>
                {roles('USER_EDIT') && (
                  <td>
                    <Button
                      startIcon={<Edit />}
                      color='success'
                      onClick={() => {
                        setModalEdit(true);
                        setDataSelected(data);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      startIcon={<Key />}
                      color='warning'
                      onClick={() => {
                        setModalResetPassword(true);
                        setDataSelected(data);
                      }}
                    >
                      Reset Password
                    </Button>
                    <Button
                      startIcon={<Delete />}
                      color='error'
                      onClick={() => handleDelete(data.id)}
                    >
                      Delete
                    </Button>
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td colSpan={5}>
                <div className='foot'>
                  <Pagination
                    skip={parameterGetData.skip}
                    take={parameterGetData.take}
                    dataTotal={dataTotal}
                    onChangePerPage={(value: number) =>
                      dispatch(
                        setParameterGetData({
                          ...parameterGetData,
                          skip: 0,
                          take: value,
                        })
                      )
                    }
                    onBack={() =>
                      dispatch(
                        setParameterGetData({
                          ...parameterGetData,
                          skip: parameterGetData.skip - parameterGetData.take,
                        })
                      )
                    }
                    onNext={() =>
                      dispatch(
                        setParameterGetData({
                          ...parameterGetData,
                          skip: parameterGetData.skip + parameterGetData.take,
                        })
                      )
                    }
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
