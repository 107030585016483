import { useEffect, useRef, useState } from "react";
import { esriToken } from "../../../state";

import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriTextSymbol from "@arcgis/core/symbols/TextSymbol"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { projectGet } from "../project.api";

export default function ProjectMap(props: {
    onClickGraphic: any,
}) {

    const mapRef = useRef<any>()
    const dispatch = useDispatch<any>()

    const projectList = useSelector((state: RootState) => state.project).dataList
    const parameterGetData = useSelector((state: RootState) => state.project).parameterGetData
    const center = projectList.length > 0
        ? {
            latitude: parseFloat(projectList[0].firstMarkerLocation.split(",")[1]),
            longitude: parseFloat(projectList[0].firstMarkerLocation.split(",")[0])
        }
        : null

    const [view, setView] = useState<esriMapView>()
    const [layerMarker, setLayerMarker] = useState<any>()

    useEffect(() => {
        dispatch(projectGet({
            ...parameterGetData,
            take: 999999
        }))
    }, [dispatch, parameterGetData])

    useEffect(() => {
        // initialize
        esriConfig.apiKey = esriToken
        let map = new esriMap({
            basemap: "topo-vector" //"satellite"
        })
        let layerMarkerLocal = new esriGraphicsLayer()
        let layerMarkerTempLocal = new esriGraphicsLayer()
        map.add(layerMarkerLocal)
        map.add(layerMarkerTempLocal)
        let viewLocal = new esriMapView({
            container: mapRef.current,
            map: map,
            zoom: center !== null ? 15 : 5,
            center: center !== null ? [center.longitude, center.latitude] : [-99.09764528271961, 33.173506394266624],
        })
        // widget
        let viewZoom = new esriZoom({ view: viewLocal })
        viewLocal.ui.remove(viewZoom)
        let viewSearch = new esriSearch({ view: viewLocal, locationEnabled: false })
        viewLocal.ui.add(viewSearch, { position: "top-left" })
        // listener
        viewLocal.on("click", (event) => {
            viewLocal.hitTest(event)
                .then((response) => {
                    let firstResult = response.results[0]
                    if (firstResult.type === "graphic") {
                        if (firstResult.graphic.attributes?.type === "PROJECT_DOT" || firstResult.graphic.attributes?.type === "PROJECT_NAME") {
                            props.onClickGraphic(firstResult.graphic.attributes?.markerId)
                        }
                    }
                })
        })
        viewLocal.on("layerview-create", () => {
            if (document.getElementById("searchbox-input")) {
                (document.getElementById("searchbox-input") as HTMLInputElement).placeholder = "Enter adress or GPS"
            }
        })
        setView(viewLocal)
        setLayerMarker(layerMarkerLocal)
        return () => viewLocal?.destroy()
    }, [])

    useEffect(() => {
        if (view) {
            view.goTo({
                zoom: center !== null ? 15 : 5,
                center: center !== null ? [center.longitude, center.latitude] : [-99.09764528271961, 33.173506394266624]
            })
        }
    }, [center])

    useEffect(() => {
        if (layerMarker) {
            let textMarkers: Array<any> = []
            let pointMarkers: Array<any> = []
            pointMarkers = [...pointMarkers, ...projectList.map(project => {
                textMarkers.push(new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project.firstMarkerLocation.split(',')[1]),
                        longitude: parseFloat(project.firstMarkerLocation.split(',')[0])
                    }),
                    symbol: new esriTextSymbol({
                        color: "black",
                        text: project.projectNumber,
                        xoffset: 40,
                        yoffset: -25,
                        font: {
                            size: 12,
                            family: "Roboto"
                        }
                    }),
                    attributes: {
                        type: "PROJECT_TEXT",
                        markerId: project.id
                    }
                }))
                return new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project.firstMarkerLocation.split(',')[1]),
                        longitude: parseFloat(project.firstMarkerLocation.split(',')[0])
                    }),
                    symbol: new esriMarkerSymbol({
                        style: "circle",
                        color: "red"
                    }),
                    attributes: {
                        type: "PROJECT_DOT",
                        markerId: project.id
                    }
                })
            })]
            layerMarker.removeAll()
            if (pointMarkers.length > 0) {
                layerMarker.addMany(pointMarkers)
            }
            if (textMarkers.length > 0) {
                layerMarker.addMany(textMarkers)
            }
        }
    }, [layerMarker, projectList])

    return (
        <div className="arcgis-container">
            <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
        </div>
    )
}