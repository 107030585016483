export interface InterfaceMarkerPoint {
    latitude: number
    longitude: number
    id?: any
}
export interface InterfaceMarkerLine extends Array<InterfaceMarkerPoint> { }

export interface InterfaceFieldingMarker {
    lineData: Array<InterfaceMarkerLine>
    shapeData: Array<InterfaceMarkerLine>
    dotLongLat: string
    colorCode: string
    subject: string
    notes: string
    timeOffset: number
    requestedFielding: string
    requestedAmountType: null | 0 | 1
    requestedAmountTypeString: any
    actualFielding: string
    customTypeId: any
    customTypeString: string | null
    id?: string
    images?: Array<InterfaceFieldingDetailMarkerAttachment>
}

export interface InterfaceFieldingMarkerProgress {
    customTypeId: string
    progressPercentage: number
}

export interface InterfaceFieldingMarkerList extends Array<InterfaceFieldingMarker> { }

export interface InterfaceFieldingMarkerProgrestList extends Array<InterfaceFieldingMarkerProgress> { }

export const InitialFieldingMarker: InterfaceFieldingMarker = {
    lineData: [],
    shapeData: [],
    dotLongLat: "",
    colorCode: "",
    subject: "",
    notes: "",
    timeOffset: 0,
    requestedFielding: "",
    requestedAmountType: null,
    requestedAmountTypeString: null,
    actualFielding: "",
    customTypeId: null,
    customTypeString: null
}

export interface InterfaceFieldingNotes {
    id: string
    createdById: string
    createdDateString: string
    content: string
    fielderIds: Array<string>
    fielderNames: Array<string>
    comments: Array<{
        comment: string
        name: string
        dateTimeString: string
    }>
}

export interface InterfaceFiledingType {
    id: string
    name: string
}
export interface InterfaceFiledingTypeList extends Array<InterfaceFiledingType> { }

export interface InterfaceFieldingEdit {
    token: string
    fieldingRequestId: string
    fielderIds: Array<string>
    dueDateString: string
    colorCode: string
    customTypeId: any
    requestedStartDateString: string | null
    requestedDueDateString: string | null
    editedFieldingRequestMarkers: Array<{
        timeOffset: number
        fieldingRequestMarkerId: string
        lineData: string
        dotLongLat: string
        colorCode: string
        subject: string
        notes: string
    }>
}

export interface InterfaceFieldingDataDetail {
    fieldingRequestId: string
    fieldingRequest: string
    dueDate: string
    totalPoles: number
    totalCompletedPoles: number
    status: string
    fieldingProgress: string
    firstPoleLongLat: string
    type: number
    lastUpdate: string
    fieldingCategory: number
    totalMeasurementMarkers: number
    totalCompletedMeasurementMarkers: number
    firstMeasurementMarkerLongLat: string
    firstMarkerLocation: string
    fieldingRequestNotes: Array<InterfaceFieldingNotes>
    averageProgressPercentage: number,
    milesTotal: number
}
export interface InterfaceFieldingData {
    jobNumberId: string
    projectId: string
    title: string
    lastFieldingRequestDueDate: string
    fieldingRequestCount: number
    notif: string
    jobStatus: number,
    isHotJob: boolean,
    details: Array<InterfaceFieldingDataDetail>
}
export interface InterfaceFieldingDataList extends Array<InterfaceFieldingData> { }

export interface InterfaceFieldingDetailAttachment {
    id: string
    fieldingRequestId: string
    fieldingRequest: string
    fileName: string
    fileNameWithExt: string
    filePath: string
    isOfficeUpload: true
    selectedUploadTimeOffset: number
    detectedImageTakenDate: string
    createdDate: string
    uploadDateString: string
    createdById: string
    uploadedBy: string
    convertedDetectedImageTakenDate: string
}
export interface InterfaceFieldingDetailMarkerAttachment {
    id: string
    fileType: number
    fileName: string
    filePath: string
    selectedUploadTimeOffset: number
    detectedImageTakenDate: string
    fileLength: number
    serverDetectedImageTakenDate: string
    uploadDateString: string
    uploadedBy: string
}

export interface InterfaceDownloadLog {
    downloadedDate: string
    downloadedTime: string
    userName: string
    travelLogs: Array<InterfaceDownloadTravelLog>
    selected?: InterfaceDownloadTravelLog
    locationLogs: Array<InterfaceDownloadLocationLog>
}
export interface InterfaceFieldingLog {
    startDate: string
    endDate: string
    createdBy: string
}
export interface InterfaceDownloadTravelLog {
    dateTime: string
    miles: number
}
export interface InterfaceDownloadLocationLog {
    dateTime: string
    longLat: string
}

export interface InterfaceFieldingDetail {
    id: string
    projectId: string
    name: string
    project: string
    dueDateString: string
    colorCode: string
    firstMarkerLocation: string
    fielderIds: Array<string>
    ftrFielderIds: Array<string>
    ftrFielderQuantity: number
    fielderNames: Array<string>
    mobileDownloadLogs: Array<InterfaceDownloadLog>
    fieldingRequestMarkerFieldingLogs: Array<{
        startDate: string
        endDate: string
        createdBy: string
    }>
    fieldingRequestAttachments: Array<InterfaceFieldingDetailAttachment>
    fieldingRequestMarkerImages: Array<InterfaceFieldingDetailMarkerAttachment>
    projectDocuments: Array<string>
    projectLinks: Array<string>
    fieldingRequestMarkers: InterfaceFieldingMarkerList
    fieldingRequestMarkersProgress: InterfaceFieldingMarkerProgrestList
    customTypeId: any
    customTypeString: string | null
    projectLocation: string
    projectLocationExtent: {
        xmin: string
        ymin: string
        xmax: string
        ymax: string
    }
    requestedStartDateString: string
    requestedDueDateString: string
    fieldingRequestNotes: Array<InterfaceFieldingNotes>
}

export interface InterfaceFieldingAssignedActive {
    id: string
    name: string
    project: string
    colorCode: string
    firstMarkerLocation: string
}

export interface InterfaceFieldingAssignedActiveList extends Array<InterfaceFieldingAssignedActive> { }

export const InitialFieldingDetail: InterfaceFieldingDetail = {
    id: "",
    projectId: "",
    name: "",
    project: "",
    dueDateString: "",
    colorCode: "",
    firstMarkerLocation: "",
    fielderIds: [],
    ftrFielderIds: [],
    ftrFielderQuantity: 0,
    fielderNames: [],
    mobileDownloadLogs: [],
    fieldingRequestMarkerFieldingLogs: [],
    fieldingRequestAttachments: [],
    fieldingRequestMarkerImages: [],
    projectDocuments: [],
    projectLinks: [],
    fieldingRequestMarkers: [],
    fieldingRequestMarkersProgress: [],
    customTypeId: "",
    customTypeString: "",
    projectLocation: "",
    projectLocationExtent: {
        xmin: "",
        ymin: "",
        xmax: "",
        ymax: ""
    },
    requestedStartDateString: "",
    requestedDueDateString: "",
    fieldingRequestNotes: []
}

export interface InterfaceFieldingNameList
    extends Array<{
        id: string
        name: string
    }> { }

export interface InterfaceToolNameList
    extends Array<{
        id: string
        name: string
    }> { }

export interface InterfaceFieldingMarkerAdd {
    id: ""
    fieldingRequestId: string
    type: 0
    lineData: string
    shapeData: string
    subject: string
    notes: string
    markerColor: 0
    dotLongLat: string
    colorCode: string
    timeOffset: number
    customTypeId: string | any | undefined
    requestedFielding: string | any | undefined
}

export interface InterfaceFieldingNoteCreate {
    token: string
    content: string
    fielderIdList: Array<string>
    fieldingRequestId: string
}
export const InitialFieldingNoteCreate: InterfaceFieldingNoteCreate = {
    token: "",
    content: "",
    fielderIdList: [],
    fieldingRequestId: ""
}

export interface InterfaceFieldingNoteCommentCreate {
    token: string
    comment: string
    fieldingRequestNoteId: string
}

export interface InterfaceFieldingAssignFtr {
    token: string
    fieldingRequestId: string
    fielderIds: Array<string>
    ftrFielderQuantity: number
}

export interface InterfaceFieldingNotification {
    fieldingRequestId: string
    fieldingRequest: string
    project: string
    assignedDate: string
    assignedTime: string
    isRead: boolean
    sortDate: string
    fieldingRequestNoteId: string
    from: string
    content: string
    isFirstToReceive: boolean
    fieldingRequestDueDate: string
    fieldingRequestRequestedStartDate: string
    fieldingRequestRequestedDate: string,
    isHotJob: Boolean
}
