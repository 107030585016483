import { useEffect, useRef, useState } from "react"
import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriPolygon from "@arcgis/core/geometry/Polygon"
import esriSimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol"
import esriSketch from "@arcgis/core/widgets/Sketch"
import { webMercatorToGeographic } from "@arcgis/core/geometry/support/webMercatorUtils"

import { esriToken } from "../../../state"
import { InterfaceMarkerLine, InterfaceMarkerPoint } from "../../fielding/fielding.interface"
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material"
import { ShapeLine, Undo, Clear } from "@mui/icons-material"

export default function MyAccountArea(props: {
    center: InterfaceMarkerPoint | null
    shape: InterfaceMarkerLine
    setShape: (params: InterfaceMarkerLine) => void
}) {
    const mapRef = useRef<any>()

    const [layerMarker, setLayerMarker] = useState<any>()
    const [latLngClick, setLatLngClick] = useState({ lat: 0, lng: 0 })
    const [palleteActive, setPalleteActive] = useState(0)

    const [sketch, setSketch] = useState<esriSketch>()
    const [layerMarkerTemp, setLayerMarkerTemp] = useState<any>()

    const [tempPolygon, setTempPolygon] = useState<InterfaceMarkerLine>([])

    const [ClearShape, setClearShape] = useState(true)
    const [LoadingShape, setLoadingShape] = useState(true)

    useEffect(() => {
        // initialize
        esriConfig.apiKey = esriToken
        let map = new esriMap({
            basemap: "topo-vector", //"satellite"
        })
        let layerMarkerLocal = new esriGraphicsLayer()
        let layerMarkerTempLocal = new esriGraphicsLayer()
        map.add(layerMarkerLocal)
        map.add(layerMarkerTempLocal)
        let view = new esriMapView({
            container: mapRef.current,
            map: map,
            zoom: props.center !== null ? 15 : 5,
            center:
                props.center !== null
                    ? [props.center.longitude, props.center.latitude]
                    : [-99.09764528271961, 33.173506394266624],
        })
        setLayerMarker(layerMarkerLocal)
        // widget
        let viewZoom = new esriZoom({ view: view })
        view.ui.remove(viewZoom)
        let viewSearch = new esriSearch({ view: view, locationEnabled: false })
        view.ui.add(viewSearch, { position: "top-left" })
        // listener
        view.on("click", (event) => {
            view.hitTest(event).then((response) => {
                setLatLngClick({
                    lat: response.results[0].mapPoint.latitude,
                    lng: response.results[0].mapPoint.longitude,
                })
            })
        })
        view.on("layerview-create", () => {
            if (document.getElementById("searchbox-input")) {
                ; (document.getElementById("searchbox-input") as HTMLInputElement).placeholder = "Enter adress or GPS"
            }
        })
        const sketchLocal = new esriSketch({
            layer: layerMarkerTempLocal,
            view: view
        })
        setSketch(sketchLocal)
        setLayerMarkerTemp(layerMarkerTempLocal)

        setTimeout(() => {
            setLoadingShape(false)
        }, 500)

        return () => view?.destroy()
    }, [ClearShape])

    useEffect(() => {
        if (palleteActive === 0) {
            sketch?.cancel()
        }
        else if (palleteActive === 3) {
            sketch?.create("polygon")
        }
        sketch?.on("create", function (event) {
            if (event.state === "complete") {
                layerMarkerTemp.removeAll()
                var polygonGeometry = event.graphic.geometry;
                var wgs84Geometry = webMercatorToGeographic(polygonGeometry);
                const geometry: any = wgs84Geometry
                if (geometry.rings && geometry.rings.length > 0) {
                    const newShapeData: InterfaceMarkerLine = geometry.rings[0].map((latlng: any) => {
                        return {
                            latitude: latlng[1],
                            longitude: latlng[0]
                        }
                    })
                    setTempPolygon(newShapeData)
                }
            }
        });
    }, [palleteActive])

    useEffect(() => {
        if (tempPolygon.length > 0) {
            props.setShape(tempPolygon)
        }
    }, [tempPolygon])

    useEffect(() => {
        const { lat, lng } = latLngClick
        if (lat && lng) {
            if (palleteActive === 3) {
                props.setShape([...(props.shape || []), { latitude: lat, longitude: lng }])
            }
        }
    }, [latLngClick])

    useEffect(() => {
        if (layerMarker) {
            layerMarker.removeAll()
            if (props.center !== null) {
                let pointMarker = new esriGraphics({
                    geometry: new esriPoint({
                        latitude: props.center?.latitude,
                        longitude: props.center?.longitude,
                    }),
                    symbol: new esriMarkerSymbol({
                        style: "circle",
                        color: "red",
                    }),
                })
                layerMarker.addMany([pointMarker])
            }
            let shapeMarker = new esriGraphics({
                geometry: new esriPolygon({
                    rings: [props.shape.map((shape) => [shape.longitude, shape.latitude])],
                }),
                symbol: new esriSimpleFillSymbol({
                    color: [10, 10, 10, 0.5],
                    outline: {
                        color: "red",
                        width: 2,
                    },
                }),
                attributes: {
                    type: "FIELDING_SHAPE",
                },
            })
            layerMarker.addMany([shapeMarker])
        }
    }, [layerMarker, props.center, props.shape])

    const handleUndo = () => {
        if (props.shape.length > 0) {
            props.setShape(props.shape.filter((s, i) => i !== (props.shape.length || 1) - 1))
        }
    }

    const handleClear = () => {
        props.setShape([])
        setClearShape(!ClearShape)
        setLoadingShape(true)
    }

    return (
        <div
            className="arcgis-container"
            style={{
                overflow: LoadingShape ? "hidden" : "auto",
            }}
        >
            {LoadingShape && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        position: "absolute",
                        zIndex: 1000,
                        top: "-2%",
                        left: "-2%",
                        width: "104%",
                        height: "104%",
                        background: "rgba(0,0,0,0.1)",
                    }}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}

            <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
            <div className="map-pallete top-right">
                Tools:
                <Tooltip title="Area" placement="left-end">
                    <IconButton
                        sx={{ border: palleteActive === 3 ? 1 : undefined }}
                        color={palleteActive === 3 ? "primary" : undefined}
                        onClick={() => setPalleteActive(palleteActive === 3 ? 0 : 3)}
                    >
                        <ShapeLine />
                    </IconButton>
                </Tooltip>
                <Tooltip title="undo" placement="left-end">
                    <IconButton color={palleteActive === 3 ? "primary" : undefined} onClick={handleUndo}>
                        <Undo />
                    </IconButton>
                </Tooltip>
                <Tooltip title="clear" placement="left-end">
                    <IconButton color={palleteActive === 3 ? "primary" : undefined} onClick={handleClear}>
                        <Clear />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    )
}
