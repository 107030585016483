import { configureStore } from '@reduxjs/toolkit'

import authReducer from './pages/auth/auth.reducer'
import projectReducer from './pages/project/project.reducer'
import accountReducer from './pages/account/account.reducer'
import myAccountReducer from './pages/myaccount/myAccount.reducer'
import fieldingReducer from './pages/fielding/fielding.reducer'
import expenseReducer from './pages/expense/expense.reducer'

const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: {
        auth: authReducer,
        project: projectReducer,
        account: accountReducer,
        myAccount: myAccountReducer,
        fielding: fieldingReducer,
        expense: expenseReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>

export default store