import { Box, Button, Card, CardActions, CardContent, CardHeader, IconButton, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { Fragment, useEffect, useState } from "react"
import {
    fieldingAssignFtrAccept,
    fieldingAssignFtrDecline,
    fieldingClearNotification,
    fieldingGetNotification,
    fieldingReadNotification
} from "../../fielding/fielding.api"
import { InterfaceFieldingNotification } from "../../fielding/fielding.interface"
import { ArrowBack } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import CustomModal from "../../../shared/customModal/CustomModal"
import NotificationNoteReply from "./NotificationNoteReply"
import notificationSuccess from "../../../shared/notificationSuccess"
import CustomLoading from "../../../shared/CustomLoading"
import ButtonOutlined from "../../../shared/ButtonOutlined"
import hot from "../../../assets/images/hot.png"

export default function Notification() {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()

    const { dataNotificationList } = useSelector((state: RootState) => state.fielding)

    const [noteIdSelected, setNoteidSelected] = useState("")

    useEffect(() => {
        dispatch(fieldingGetNotification())
        dispatch(fieldingReadNotification())
    }, [])

    const isShowActions = (data: InterfaceFieldingNotification) => {
        return Boolean(data.isFirstToReceive || data.fieldingRequestNoteId)
    }

    const handleAccept = (fieldingRequestId: string) => {
        dispatch(fieldingAssignFtrAccept(fieldingRequestId))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("")
                    dispatch(fieldingGetNotification())
                }
            })
    }

    const handleDecline = (fieldingRequestId: string) => {
        dispatch(fieldingAssignFtrDecline(fieldingRequestId))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("")
                    dispatch(fieldingGetNotification())
                }
            })
    }

    const handleClear = () => {
        dispatch(fieldingClearNotification())
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(fieldingGetNotification())
                }
            })
    }

    var lastDate = ""

    return (
        <Box height={"100vh"} overflow={"auto"} bgcolor={"#e5e5e5"}>
            <CustomLoading />
            <CustomModal
                open={Boolean(noteIdSelected)}
                onClose={() => setNoteidSelected("")}
                title="Reply Note"
                size="sm"
                component={
                    <NotificationNoteReply
                        noteId={noteIdSelected}
                        onSubmit={() => dispatch(fieldingGetNotification())}
                    />
                }
            />
            <Box bgcolor={"#063058"} color={"white"} display={"flex"} alignItems={"center"} padding={2}>
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack sx={{ color: "white" }} />
                </IconButton>
                <Typography>Notification</Typography>
            </Box>
            <Box padding={3}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <Button variant="contained" onClick={handleClear}>
                        Clear All Notification
                    </Button>
                </Box>
                {dataNotificationList.map((data, dataIndex) => {
                    const isShowDate = lastDate !== data.assignedDate
                    lastDate = data.assignedDate
                    return (
                        <Fragment key={dataIndex}>
                            {isShowDate && (
                                <Typography variant="body1" padding={2}>
                                    {data.assignedDate}
                                </Typography>
                            )}
                            <Card sx={{ marginBottom: 1 }}>
                                <CardHeader
                                    title={(
                                        <Box display={"flex"} gap={1} alignItems={"center"}>
                                            <Typography variant="h5" color={data.isHotJob ? "red" : undefined}>{data.project}</Typography>
                                            {data.isHotJob && (
                                                <img src={hot} alt="hot" style={{ width: "20px", height: "20px" }} />
                                            )}
                                            <Typography variant="h5">-</Typography>
                                            <Typography variant="h5">{data.fieldingRequest}</Typography>
                                        </Box>
                                    )}
                                    action={
                                        <div>
                                            <span style={{ fontSize: "10px", paddingLeft: 10 }}>
                                                {data.assignedTime}
                                            </span>
                                        </div>
                                    }
                                />
                                {Boolean(data.fieldingRequestNoteId) && (
                                    <CardContent>
                                        <Typography variant="h6">{data.content}</Typography>
                                        from : {data.from}
                                    </CardContent>
                                )}
                                {isShowActions(data) && (
                                    <CardActions sx={{ display: "flex", flexDirection: "row" }}>
                                        <Box sx={{ paddingLeft: "8px" }}>
                                            <Box sx={{ display: "flex" }}>
                                                <Typography sx={{ width: "70px", fontWeight: "bold" }}>
                                                    Due date{" "}
                                                </Typography>
                                                <Typography>: {data.fieldingRequestDueDate}</Typography>
                                            </Box>
                                            <Box sx={{ display: "flex" }}>
                                                <Typography sx={{ width: "70px", fontWeight: "bold" }}>
                                                    Date range{" "}
                                                </Typography>
                                                <Typography>
                                                    :{" "}
                                                    {data.fieldingRequestRequestedStartDate !== "-" &&
                                                        data.fieldingRequestRequestedDate !== "-" ? (
                                                        <>
                                                            {data.fieldingRequestRequestedStartDate !== "-"
                                                                ? data.fieldingRequestRequestedStartDate
                                                                : ""}{" "}
                                                            -{" "}
                                                            {data.fieldingRequestRequestedDate !== "-"
                                                                ? data.fieldingRequestRequestedDate
                                                                : ""}
                                                        </>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} flexGrow={1} justifyContent={"flex-end"} gap={1}>
                                            {data.isFirstToReceive && (
                                                <Fragment>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => handleDecline(data.fieldingRequestId)}
                                                    >
                                                        Decline
                                                    </Button>
                                                    <ButtonOutlined
                                                        color="success"
                                                        onClick={() => handleAccept(data.fieldingRequestId)}
                                                    >
                                                        Accept
                                                    </ButtonOutlined>
                                                    <Button
                                                        variant="contained"
                                                        // color="success"
                                                        onClick={() =>
                                                            navigate(
                                                                `/fielding/detail?preview=true&id=${data.fieldingRequestId}`
                                                            )
                                                        }
                                                    >
                                                        View On Map
                                                    </Button>
                                                </Fragment>
                                            )}
                                            {Boolean(data.fieldingRequestNoteId) && (
                                                <ButtonOutlined
                                                    onClick={() => setNoteidSelected(data.fieldingRequestNoteId)}
                                                >
                                                    Reply
                                                </ButtonOutlined>
                                            )}
                                        </Box>
                                    </CardActions>
                                )}
                            </Card>
                        </Fragment>
                    )
                })}
            </Box>
        </Box>
    )
}
