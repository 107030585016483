import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { baseUrl } from "../../state"
import { userInfo } from "../../shared/utils"
import {
    InterfaceAccountCreate,
    InterfaceAccountEdit,
    InterfaceAccountGet,
    InterfaceAccountResetPassword
} from "./account.interface"
import handleError from "../../shared/handleError"

export const createAccount = createAsyncThunk("createAccount", async (data: InterfaceAccountCreate) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Account/FPCreate",
            data: data
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const editAccount = createAsyncThunk("editAccount", async (data: InterfaceAccountEdit) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Account/FPEdit",
            data: data
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const getAccount = createAsyncThunk("getAccount", async (data: InterfaceAccountGet) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Account/FPGetListByCompanyId",
            data: data
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const getAllAccount = createAsyncThunk("getAllAccount", async () => {
    try {
        const response = await axios({
            method: "get",
            url: baseUrl + "/api/Account/GetListByCompanyId",
            params: { companyId: userInfo().user.companyId }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const getAccountFielder = createAsyncThunk("getAccountFielder", async () => {
    try {
        const response = await axios({
            method: "get",
            url: baseUrl + "/api/Account/GetFielders",
            params: { companyId: userInfo().user.companyId }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const getFielderRating = createAsyncThunk(
    "getFielderRating",
    async (data: { token: string; fielderId: string }) => {
        try {
            const response = await axios({
                method: "get",
                url: baseUrl + "/newapi/Account/FPGetCertainFielderRatingList",
                params: { token: data.token, fielderId: data.fielderId }
            })
            return response
        } catch (error: any) {
            handleError(error)
            return error.response
        }
    }
)

export const getFielderRatingByRequestId = createAsyncThunk(
    "getFielderRatingByRequestId",
    async (data: { token: string; fieldingRequestId: string }) => {
        try {
            const response = await axios({
                method: "get",
                url: baseUrl + "/newapi/FieldingRequest/FPGetUserFielderRatings",
                params: {
                    token: data.token,
                    fieldingRequestId: data.fieldingRequestId
                }
            })
            return response
        } catch (error: any) {
            handleError(error)
            return error.response
        }
    }
)

export const submitFielderRating = createAsyncThunk(
    "submitFielderRating",
    async (
        data: Array<{
            token: string
            fielderId: string
            rating: number
            review: string
        }>
    ) => {
        try {
            const response = await axios({
                method: "post",
                url: baseUrl + "/newapi/Account/FPRateFielder",
                data: data
            })
            return response
        } catch (error: any) {
            handleError(error)
            return error.response
        }
    }
)

export const getAccountFielderDetailList = createAsyncThunk(
    "getAccountFielderDetailList",
    async (data: {
        token: string
        companyId: string
        skillTypeId: string | null
        startDateFilter: string
        endDateFilter: string
    }) => {
        try {
            const response = await axios({
                method: "post",
                url: baseUrl + "/newapi/Account/GetFPFielderListBySkillTypeId",
                data: data
            })
            return response
        } catch (error: any) {
            handleError(error)
            return error.response
        }
    }
)

export const deleteAccount = createAsyncThunk("deleteAccount", async (id: string) => {
    try {
        const response = await axios({
            method: "delete",
            url: baseUrl + "/api/Account/Delete",
            params: { targetUserId: id }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const resetPassword = createAsyncThunk("resetPassword", async (data: InterfaceAccountResetPassword) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Account/NewResetPassword",
            data: data
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const accountStorageGet = createAsyncThunk("accountStorageGet", async (SelectedUser: string | number) => {
    try {
        let alluser = SelectedUser === 9999999999
        let targetuserid = SelectedUser === 9999999999 ? "" : userInfo()?.user?.id === SelectedUser ? "" : SelectedUser

        const response = await axios({
            method: "get",
            url: baseUrl + "/newapi/Account/GetUserStorageDetails",
            params: { token: userInfo().token, alluser: alluser, targetuserid: targetuserid }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const accountStorageGetByProject = createAsyncThunk("accountStorageGetByProject", async (projectId: string) => {
    try {
        const response = await axios({
            method: "get",
            url: baseUrl + "/newapi/Account/GetUserStorageDetailsByProjectId",
            params: {
                token: userInfo().token,
                projectId: projectId
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const accountStorageGetByFielding = createAsyncThunk("accountStorageGetByFielding", async (props: any) => {
    try {
        let alluser = props?.SelectedUser === 9999999999
        let targetuserid =
            props?.SelectedUser === 9999999999
                ? ""
                : userInfo()?.user?.id === props?.SelectedUser
                ? ""
                : props.SelectedUser

        const response = await axios({
            method: "get",
            url: baseUrl + "/newapi/Account/GetUserStorageDetailsByFieldingRequestId",
            params: {
                token: userInfo().token,
                fieldingRequestId: props.fieldingRequestId,
                alluser: alluser,
                targetuserid: targetuserid
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const accountStorageGetByTools = createAsyncThunk("accountStorageGetByTools", async (props: any) => {
    try {
        let alluser = props?.SelectedUser === 9999999999
        let targetuserid =
            props?.SelectedUser === 9999999999
                ? ""
                : userInfo()?.user?.id === props?.SelectedUser
                ? ""
                : props.SelectedUser
        const response = await axios({
            method: "get",
            url: baseUrl + "/newapi/account/GetUserStorageDetailsByMarkerId",
            params: {
                token: userInfo().token,
                fieldingRequestMarkerId: props.fieldingRequestMarkerId,
                alluser: alluser,
                targetuserid: targetuserid
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const accountValidateToken = createAsyncThunk("accountValidateToken", async (token: string) => {
    try {
        const response = await axios({
            method: "get",
            url: baseUrl + "/newapi/Account/ValidateUploadToken",
            params: { UploadToken: token }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const projectGetNotification = createAsyncThunk("projectGetNotification", async () => {
    try {
        const response = await axios({
            method: "get",
            url: baseUrl + "/newapi/Account/FPGetNotifications",
            params: {
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const projectReadNotification = createAsyncThunk(
    "projectReadNotification",
    async (notificationId: string = "") => {
        try {
            let params: any = {
                token: userInfo().token
            }
            if (notificationId !== "") params = { ...params, notificationId: notificationId }
            const response = await axios({
                method: "put",
                url: baseUrl + "/newapi/Account/FPReadNotifications",
                params: params
            })
            return response
        } catch (error: any) {
            handleError(error)
            return error.response
        }
    }
)

export const projectClearNotification = createAsyncThunk("projectClearNotification", async () => {
    try {
        const response = await axios({
            method: "put",
            url: baseUrl + "/newapi/Account/FPClearNotifications",
            params: {
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})
