import { Box, Button, Card, CardContent, Chip, Divider, Grid, IconButton, Modal, Slide, Slider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { InterfaceProjectDetail } from "../project.interface";
import { ControlPoint, KeyboardArrowLeft, KeyboardArrowRight, West } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { projectGetDetail } from "../project.api";
import { useNavigate } from "react-router-dom";
import useAddress from "../../../shared/useAddress";
import { fieldingComplete, fieldingReactive } from "../../fielding/fielding.api";

export default function ProjectFieldingDetail(props: { projectId: string | undefined, onClose: () => void }) {

    const theme = useTheme()
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    const address = useAddress()
    const isMobile = useMediaQuery("(max-width:768px)")

    const [projectDetail, setProjectDetail] = useState<InterfaceProjectDetail | undefined>(undefined)
    const [fieldingIndex, setFieldingIndex] = useState(0)
    const [fieldingAddressList, setFieldingAddressList] = useState<{ id: string; value: string }[]>([])

    useEffect(() => {
        if (props.projectId !== undefined) {
            dispatch(projectGetDetail(props.projectId))
                .unwrap()
                .then(async (response: any) => {
                    let data: InterfaceProjectDetail = response.data.data
                    setFieldingIndex(0)
                    setProjectDetail(data)
                    const addressToAdd = await address.fillAddressMultiple(
                        data.projectFieldingRequests
                            .map((fieldingRequest) => {
                                return {
                                    id: fieldingRequest.fieldingRequestId,
                                    markers: fieldingRequest.fieldingRequestMarkers.map((marker) => {
                                        return {
                                            ...marker,
                                            lineData: JSON.parse(marker.lineData),
                                            shapeData: JSON.parse(marker.shapeData)
                                        }
                                    })
                                }
                            }),
                        true
                    )
                    setFieldingAddressList(addressToAdd)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.projectId])

    if (projectDetail === undefined) {
        return <></>
    }

    const fieldingRequestSelected = projectDetail.projectFieldingRequests[fieldingIndex]
    const progress = fieldingRequestSelected.fieldingRequestMarkers.reduce((total, marker) => total + marker.progressPercentage, 0) / fieldingRequestSelected.fieldingRequestMarkers.length
    const isFieldingActive = fieldingRequestSelected.status === "Active"

    const handleFieldingStatus = () => {
        if (isFieldingActive) {
            dispatch(fieldingComplete(fieldingRequestSelected.fieldingRequestId))
                .unwrap()
                .then(() => {
                    dispatch(projectGetDetail(projectDetail.id))
                        .unwrap()
                        .then((response: any) => {
                            let data: InterfaceProjectDetail = response.data.data
                            setProjectDetail(data)
                        })
                })
        }
        else {
            dispatch(fieldingReactive(fieldingRequestSelected.fieldingRequestId))
                .unwrap()
                .then(() => {
                    dispatch(projectGetDetail(projectDetail.id))
                        .unwrap()
                        .then((response: any) => {
                            let data: InterfaceProjectDetail = response.data.data
                            setProjectDetail(data)
                        })
                })
        }
    }

    return (
        <Modal open={Boolean(props.projectId)} onClose={props.onClose} sx={{ display: "flex", justifyContent: "flex-end", zIndex: 1 }}>
            <Slide direction="left" in={Boolean(props.projectId)}>
                <Box height={"100vh"} width={isMobile ? "100vw" : "35vw"} bgcolor={theme.palette.background.default}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} padding={1}>
                        <Box display={"flex"} alignItems={"center"}>
                            <IconButton onClick={props.onClose}>
                                <West />
                            </IconButton>
                            <Typography variant="body1" fontWeight={"bold"}>
                                Request Detail
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                startIcon={<KeyboardArrowLeft />}
                                disabled={fieldingIndex === 0}
                                onClick={() => setFieldingIndex(last => last - 1)}
                            >
                                Previous
                            </Button>
                            <Button
                                endIcon={<KeyboardArrowRight />}
                                disabled={fieldingIndex === projectDetail.projectFieldingRequests.length - 1}
                                onClick={() => setFieldingIndex(last => last + 1)}
                            >
                                Next
                            </Button>
                        </Box>
                    </Box>
                    <Divider />
                    <Box padding={2}>
                        <Grid container spacing={2} rowSpacing={3}>
                            <Grid item xs={6}>
                                <Typography fontWeight={"bold"}>Request Number</Typography>
                                <Typography>{fieldingRequestSelected.fieldingRequest}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontWeight={"bold"}>Created by</Typography>
                                <Typography>{fieldingRequestSelected.createdByName}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography fontWeight={"bold"}>Fielding Type</Typography>
                                <Typography>{fieldingRequestSelected.fieldingRequestMarkers[0].customTypeString}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography fontWeight={"bold"}>Location</Typography>
                                <Typography>{fieldingAddressList.find(address => address.id === fieldingRequestSelected.fieldingRequestId)?.value}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontWeight={"bold"}>Due Date</Typography>
                                <Typography>{fieldingRequestSelected.dueDateString}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontWeight={"bold"}>Last Update</Typography>
                                <Typography>{fieldingRequestSelected.modifiedDateString}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Button color={isFieldingActive ? "success" : "primary"} onClick={handleFieldingStatus}>
                                        {fieldingRequestSelected.status}
                                    </Button>
                                    <Typography fontWeight={"bold"} color={isFieldingActive ? theme.palette.success.main : theme.palette.primary.main}>{progress}%</Typography>
                                </Box>
                                <Slider value={progress} valueLabelDisplay="auto" color={isFieldingActive ? "success" : "primary"} sx={{ cursor: "default" }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Typography fontWeight={"bold"}>Fielder</Typography>
                                    <Button startIcon={<ControlPoint />} variant="outlined" onClick={() => navigate(`/project/assign?id=${fieldingRequestSelected.fieldingRequestId}`)}>Assign Fielder</Button>
                                </Box>
                                <Card variant="outlined" sx={{ marginTop: 1 }}>
                                    <CardContent sx={{ height: 200, overflow: "auto" }}>
                                        <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                                            {fieldingRequestSelected.fielderNames.map(fielder => (
                                                <Chip label={fielder} />
                                            ))}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Total Miles : {fieldingRequestSelected.milesTotal} Miles</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}